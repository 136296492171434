import { defineStore } from "pinia";
import { useAuthStore } from "../app/auth";
import { differenceInCalendarDays, parseISO } from "date-fns";

export const useSubscriptionStore = defineStore({

  id: "subscriptionStore",

  state: () => ({
    $http: useNuxtApp().$http,

    authStore: useAuthStore(),

    defaultDaysForBlock: 7

  }),

  getters: {

    company() {
      return this.authStore.company;
    },

    subscription() {
      return this.company?.subscription;
    },

    billingInformation() {
      return this.company?.billing_information; // ??
    },

    unpaidCharges() {
      return this.subscription?.unpaid_charges;
    },

    hasUnpaidCharges() {
      return this.unpaidCharges && this.unpaidCharges.length > 0;
    },

    hasPastDueCharges() {
      if (!this.hasUnpaidCharges) {
        return false;
      }

      return this.unpaidCharges.some(charge => {
        return differenceInCalendarDays(new Date(), parseISO(charge.expire_at)) > 0;
      });
    },

    unpaidInvoices() {
      return this.subscription?.unpaid_invoices;
    },

    hasUnpaidInvoices() {
      return this.unpaidInvoices && this.unpaidInvoices.length > 0;
    },

    hasPastDueInvoices() {
      if (!this.hasUnpaidInvoices) {
        return false;
      }

      return this.unpaidInvoices.some(invoice => {
        return differenceInCalendarDays(new Date(), parseISO(invoice.due_date)) > 0;
      });
    },

    pastDueDays() {
      // return past due days of most past due invoice

      if (this.subscription?.integration == 'app') {

        if (!this.hasUnpaidInvoices) {
          return 0;
        }

        return this.unpaidInvoices?.reduce((acc, invoice) => {
          const days = differenceInCalendarDays(new Date(), parseISO(invoice.due_date));
          return days > acc ? days : acc;
        }, 0);
      
      } else {

        if (!this.hasUnpaidCharges) {
          return 0;
        }

        return this.unpaidCharges?.reduce((acc, charge) => {
          const days = differenceInCalendarDays(new Date(), parseISO(charge.expire_at));
          return days > acc ? days : acc;
        }, 0);
      }
    },

    plan() {
      return this.subscription?.plan;
    },

    isFreeAccount() {
      return this.company?.is_free_account;
    },

    hasSubscription() {
      return this.subscription !== null;
    },

    isOnTrial() {
      return !this.hasSubscription
    },

    trialEndsAt() {
      return parseISO(this.company?.free_trial_ends_at);
    },

    daysToEndTrial() {
      return differenceInCalendarDays(this.trialEndsAt, new Date());
    },

    trialEnded() {
      return this.daysToEndTrial < 0;
    },

    trialIsActive() {
      return !this.trialEnded;
    },

    subscriptionIsActive() {
      return this.subscription && this.subscription.status !== 'canceled' && this.subscription.status !== 'incomplete';
    },

    isPaymentUpToDate() {
      return this.pastDueDays <= this.defaultDaysForBlock;
    },

    isNoOnBlock() {
      if (this.company?.no_block_access_before) {
        return differenceInCalendarDays(new Date(), parseISO(this.company?.no_block_access_before)) < 0;
      }
      return false;
    },

    isBlocked() {
      if (this.isFreeAccount) {
        return false
      }

      if (this.trialIsActive) {
        return false
      }

      if (this.isNoOnBlock) {
        return false
      }

      if (this.subscriptionIsActive && this.isPaymentUpToDate) {
        return false
      }

      return true
    }
  },

  actions: {
    async getSubscriptionCharges(params = null) {
      return await this.$http.index('/subscription-charge', params);
    },

    async getInvoices() {

      let params = {
        subscription_id: this.subscription.id
      }

      return await this.$http.index('/invoice', params);
    },

    async storeCharge(subscriptionId, invoiceId) {
      return await this.$http.store('/charge', { subscription_id: subscriptionId, invoice_id: invoiceId });
    }
  }

});